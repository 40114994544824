<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showNomencladoresCars && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresCarsVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showNomencladoresCars && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresCarsEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showNomencladoresCars && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresCarsCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showNomencladoresCars && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresCarsDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresCars = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresCarsVer) {
        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:list')
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_transmision_cars:list'
            && e !== 'nom_type_auto_cars:list'
            && e !== 'nom_marca_cars:list'
            && e !== 'nom_model_cars:list'
            && e !== 'nom_combustible_cars:list'
            && e !== 'nom_caracteristica_cars:list'
            && e !== 'nom_categories_cars:list'
            && e !== 'nom_rentadora_cars:list'
            && e !== 'nom_tarifas_cars:list'
            && e !== 'nom_tarifas_x_km_cars:list'
            && e !== 'nom_edades_cars:list'
            && e !== 'nom_tags_pto_recogida_cars:list'
            && e !== 'nom_oficina_renta_cars:list'
            && e !== 'nom_suplemento_contrate_cars:list'
            && e !== 'nom_conditions_generals_cars:list'
            && e !== 'nom_tag_imagen_cars:list',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresVer = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresCars = !this.$store.state.app.showNomencladoresCars
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresCars = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresCarsEdit) {
        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:edit')
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_transmision_cars:edit'
            && e !== 'nom_type_auto_cars:edit'
            && e !== 'nom_marca_cars:edit'
            && e !== 'nom_model_cars:edit'
            && e !== 'nom_combustible_cars:edit'
            && e !== 'nom_caracteristica_cars:edit'
            && e !== 'nom_categories_cars:edit'
            && e !== 'nom_rentadora_cars:edit'
            && e !== 'nom_tarifas_cars:edit'
            && e !== 'nom_tarifas_x_km_cars:edit'
            && e !== 'nom_edades_cars:edit'
            && e !== 'nom_tags_pto_recogida_cars:edit'
            && e !== 'nom_oficina_renta_cars:edit'
            && e !== 'nom_suplemento_contrate_cars:edit'
            && e !== 'nom_conditions_generals_cars:edit'
            && e !== 'nom_tag_imagen_cars:edit',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresEdit = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresCars = !this.$store.state.app.showNomencladoresCars
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresCars = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresCarsCreate) {
        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:create')
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_transmision_cars:create'
            && e !== 'nom_type_auto_cars:create'
            && e !== 'nom_marca_cars:create'
            && e !== 'nom_model_cars:create'
            && e !== 'nom_combustible_cars:create'
            && e !== 'nom_caracteristica_cars:create'
            && e !== 'nom_categories_cars:create'
            && e !== 'nom_rentadora_cars:create'
            && e !== 'nom_tarifas_cars:create'
            && e !== 'nom_tarifas_x_km_cars:create'
            && e !== 'nom_edades_cars:create'
            && e !== 'nom_tags_pto_recogida_cars:create'
            && e !== 'nom_oficina_renta_cars:create'
            && e !== 'nom_suplemento_contrate_cars:create'
            && e !== 'nom_conditions_generals_cars:create'
            && e !== 'nom_tag_imagen_cars:create',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresCreate = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresCars = !this.$store.state.app.showNomencladoresCars
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresCars = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresCarsDelete) {
        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:destroy')
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_transmision_cars:destroy'
            && e !== 'nom_type_auto_cars:destroy'
            && e !== 'nom_marca_cars:destroy'
            && e !== 'nom_model_cars:destroy'
            && e !== 'nom_combustible_cars:destroy'
            && e !== 'nom_caracteristica_cars:destroy'
            && e !== 'nom_categories_cars:destroy'
            && e !== 'nom_rentadora_cars:destroy'
            && e !== 'nom_tarifas_cars:destroy'
            && e !== 'nom_tarifas_x_km_cars:destroy'
            && e !== 'nom_edades_cars:destroy'
            && e !== 'nom_tags_pto_recogida_cars:destroy'
            && e !== 'nom_oficina_renta_cars:destroy'
            && e !== 'nom_suplemento_contrate_cars:destroy'
            && e !== 'nom_conditions_generals_cars:destroy'
            && e !== 'nom_tag_imagen_cars:destroy',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresDelete = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresCars = !this.$store.state.app.showNomencladoresCars
      }, 10)
    },
  },
}
</script>
