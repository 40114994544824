<template>
  <fragment>
    <!--LIST-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxVer"
          hide-details
          class="my-1mdiDeleteOutline"
          @click="checkPerm(index, 'user_register:list')"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxEdit"
          hide-details
          class="my-1mdiDeleteOutline"
          @click="checkPerm(index, 'user_register:edit')"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxCreate"
          hide-details
          class="my-1mdiDeleteOutline"
          @click="checkPerm(index, 'user_register:create')"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxDelete"
          hide-details
          class="my-1mdiDeleteOutline"
          @click="checkPerm(index, 'user_register:destroy')"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      isLoading: true,
      checkboxVer: false,
      checkboxEdit: false,
      checkboxCreate: false,
      checkboxDelete: false,
    }
  },
  created() {
    this.checkboxVer = this.sourcePermisos[this.index].permisos.includes('user_register:list')
    this.checkboxEdit = this.sourcePermisos[this.index].permisos.includes('user_register:edit')
    this.checkboxCreate = this.sourcePermisos[this.index].permisos.includes('user_register:create')
    this.checkboxDelete = this.sourcePermisos[this.index].permisos.includes('user_register:destroy')
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkPerm(pos, ability) {
      let { permisos } = this.sourcePermisos[pos]
      if (permisos.includes(ability)) {
        this.sourcePermisos[pos].permisos = permisos.filter(e => e !== ability)
        if (ability === 'user_register:list') {
          this.sourcePermisos[pos].all.checkboxConfigurationVer = false
        } else if (ability === 'user_register:edit') {
          this.sourcePermisos[pos].all.checkboxConfigurationEdit = false
        } else if (ability === 'user_register:create') {
          this.sourcePermisos[pos].all.checkboxConfigurationCreate = false
        } else if (ability === 'user_register:destroy') {
          this.sourcePermisos[pos].all.checkboxConfigurationDelete = false
        }
      } else {
        permisos = permisos.push(ability)
        this.verifyPermisoZona(this.index)
      }
    },
  },
}
</script>
