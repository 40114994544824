<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showProduct"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxProductsVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showProduct"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxProductsEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showProduct"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxProductsCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showProduct"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxProductsDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showProduct = false
      if (this.sourcePermisos[this.index].all.checkboxProductsVer) {
        this.sourcePermisos[this.index].permisos.push('flight:list')
        this.sourcePermisos[this.index].permisos.push('hotel:list')
        this.sourcePermisos[this.index].permisos.push('car:list')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'flight:list' && e !== 'hotel:list' && e !== 'car:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showProduct = !this.$store.state.app.showProduct
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showProduct = false
      if (this.sourcePermisos[this.index].all.checkboxProductsEdit) {
        this.sourcePermisos[this.index].permisos.push('flight:edit')
        this.sourcePermisos[this.index].permisos.push('hotel:edit')
        this.sourcePermisos[this.index].permisos.push('car:edit')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'flight:edit' && e !== 'hotel:edit' && e !== 'car:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showProduct = !this.$store.state.app.showProduct
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showProduct = false
      if (this.sourcePermisos[this.index].all.checkboxProductsCreate) {
        this.sourcePermisos[this.index].permisos.push('flight:create')
        this.sourcePermisos[this.index].permisos.push('hotel:create')
        this.sourcePermisos[this.index].permisos.push('car:create')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'flight:create' && e !== 'hotel:create' && e !== 'car:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showProduct = !this.$store.state.app.showProduct
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showProduct = false
      if (this.sourcePermisos[this.index].all.checkboxProductsDelete) {
        this.sourcePermisos[this.index].permisos.push('flight:destroy')
        this.sourcePermisos[this.index].permisos.push('hotel:destroy')
        this.sourcePermisos[this.index].permisos.push('car:destroy')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'flight:destroy' && e !== 'hotel:destroy' && e !== 'car:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showProduct = !this.$store.state.app.showProduct
      }, 10)
    },
  },
}
</script>
